import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import KeyCloakService from "./security/keycloak-service";
import VueApexCharts from "vue3-apexcharts";
import "./style.css";
import HttpService from "./services/http-service";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { createPinia } from "pinia";

const pinia = createPinia();

// const nodeEnv = process.env.NODE_ENV;
const renderApp = () =>
  createApp(App)
    .use(router)
    .use(VueApexCharts)
    .use(VueTailwindDatepicker as any)
    .use(pinia)
    .mount("#app");

KeyCloakService.CallLogin(renderApp);
HttpService.configureAxiosKeycloak();
