<template>
  <svg
    :class="props.class"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.08172 1.81592H15.2533C18.2292 1.81592 19.9092 3.49952 19.918 6.46785V14.6196C19.918 17.5871 18.2292 19.2716 15.2533 19.2716H7.08172C4.10584 19.2716 2.41797 17.5871 2.41797 14.6196V6.46785C2.41797 3.49952 4.10584 1.81592 7.08172 1.81592ZM11.2109 15.6582C11.588 15.6582 11.9022 15.379 11.9372 15.0037V6.11001C11.9722 5.83944 11.8418 5.56801 11.6055 5.42051C11.3597 5.27214 11.0622 5.27214 10.8268 5.42051C10.5897 5.56801 10.4593 5.83944 10.4847 6.11001V15.0037C10.5293 15.379 10.8434 15.6582 11.2109 15.6582ZM15.2368 15.6582C15.6043 15.6582 15.9184 15.379 15.963 15.0037V12.1409C15.9884 11.8608 15.858 11.5998 15.6209 11.4514C15.3855 11.3031 15.088 11.3031 14.843 11.4514C14.6059 11.5998 14.4755 11.8608 14.5105 12.1409V15.0037C14.5455 15.379 14.8597 15.6582 15.2368 15.6582ZM7.85962 15.0037C7.82462 15.379 7.5105 15.6582 7.13337 15.6582C6.75712 15.6582 6.44212 15.379 6.408 15.0037V8.97273C6.38175 8.7013 6.51212 8.43161 6.74925 8.28324C6.98462 8.13486 7.283 8.13486 7.51925 8.28324C7.75462 8.43161 7.88675 8.7013 7.85962 8.97273V15.0037Z"
    />
  </svg>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps(["class", "color"]);
</script>
