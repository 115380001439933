<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5"
              >
                <button
                  type="button"
                  class="-m-2.5 p-2.5"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div
              class="flex grow flex-col gap-y-5 overflow-y-auto bg-parrot px-4 py-10 pb-4"
            >
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" class="-mx-2 space-y-1">
                      <li v-for="item in navigation" :key="item.name">
                        <a
                          :href="item.href"
                          :class="[
                            isActive(item.href)
                              ? 'bg-white bg-opacity-5 text-parrot-1'
                              : 'text-white hover:text-parrot-1 hover:bg-white hover:bg-opacity-5',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          ]"
                        >
                          <component
                            :is="item.icon"
                            :class="[
                              isActive(item.href)
                                ? 'text-parrot-1'
                                : 'text-white group-hover:text-parrot-1',
                              'h-6 w-6 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="mt-auto">
                    <a
                      href="#"
                      @click="logout()"
                      class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-white hover:bg-opacity-5 hover:text-parrot-1"
                    >
                      <ArrowLeftEndOnRectangleIcon
                        class="h-6 w-6 shrink-0 text-white group-hover:text-parrot-1"
                        aria-hidden="true"
                      />
                      Sair
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Desktop sidebar -->
  <div
    class="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:mt-16 lg:flex lg:w-72 lg:flex-col"
  >
    <div
      class="flex grow flex-col gap-y-5 overflow-y-auto bg-parrot px-4 py-10 pb-4"
    >
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li v-for="item in navigation" :key="item.name">
                <a
                  :href="item.href"
                  :class="[
                    isActive(item.href)
                      ? 'bg-white bg-opacity-5 text-parrot-1'
                      : 'text-white hover:text-parrot-1 hover:bg-white hover:bg-opacity-5',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :color="isActive(item.href) ? '#B2FF59' : 'white'"
                    :class="[
                      isActive(item.href)
                        ? 'text-parrot-1'
                        : 'text-white group-hover:text-parrot-1',
                      'h-6 w-6 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>
          <li class="mt-auto">
            <a
              href="#"
              @click="logout"
              class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-white hover:bg-opacity-5 hover:text-parrot-1"
            >
              <ArrowLeftEndOnRectangleIcon
                class="h-6 w-6 shrink-0 text-white group-hover:text-parrot-1"
                aria-hidden="true"
              />
              Sair
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineExpose } from "vue";
import { Dialog, TransitionRoot } from "@headlessui/vue";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/vue/24/outline";
import { useRoute } from "vue-router";
import KeycloakService from "@/security/keycloak-service";
import { navigation } from "@/config/navigation";

const route = useRoute();
const sidebarOpen = ref(false);

const isActive = (href: string) => {
  return route.path === href;
};

const closeSidebar = () => {
  sidebarOpen.value = false;
};

const logout = () => {
  KeycloakService.callLogOut();
  localStorage.clear();
};

defineExpose({ sidebarOpen });
</script>
