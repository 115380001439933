<template>
  <svg
    :class="props.class"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3561 8.72061H13.778C14.1452 8.72061 14.4337 8.41778 14.4337 8.0526C14.4337 7.67852 14.1452 7.38459 13.778 7.38459H11.3561C10.9889 7.38459 10.7004 7.67852 10.7004 8.0526C10.7004 8.41778 10.9889 8.72061 11.3561 8.72061ZM16.6545 4.08996C17.1878 4.08996 17.5376 4.277 17.8873 4.68671C18.237 5.09643 18.2982 5.68428 18.2195 6.2178L17.3889 12.0607C17.2316 13.1838 16.2873 14.0113 15.1769 14.0113H5.63809C4.47525 14.0113 3.5135 13.1037 3.41732 11.928L2.61295 2.21863L1.29273 1.98706C0.942999 1.92471 0.69819 1.57734 0.759392 1.22107C0.820594 0.856779 1.16158 0.615404 1.52005 0.669736L3.6053 0.989491C3.90257 1.04382 4.12115 1.29232 4.14738 1.59516L4.3135 3.59028C4.33973 3.87619 4.56705 4.08996 4.84683 4.08996H16.6545ZM5.49802 15.419C4.7636 15.419 4.16906 16.0247 4.16906 16.7728C4.16906 17.5121 4.7636 18.1178 5.49802 18.1178C6.22371 18.1178 6.81824 17.5121 6.81824 16.7728C6.81824 16.0247 6.22371 15.419 5.49802 15.419ZM15.3341 15.419C14.5997 15.419 14.0051 16.0247 14.0051 16.7728C14.0051 17.5121 14.5997 18.1178 15.3341 18.1178C16.0598 18.1178 16.6543 17.5121 16.6543 16.7728C16.6543 16.0247 16.0598 15.419 15.3341 15.419Z"
    />
  </svg>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps(["class", "color"]);
</script>
