import { defineStore } from "pinia";
import HttpService from "@/services/http-service";
import KeycloakService from "@/security/keycloak-service";

export interface Tenant {
  name: string;
  tenant: string;
}

export const useProfileStore = defineStore("profile", {
  state: () => ({
    profile: null as any | null,
    userNavigation: [] as Tenant[],
    selectedTenant: null as Tenant | null,
    loading: false,
    error: null as string | null,
  }),
  getters: {
    fullName: (state) => state.profile?.nome || "N/A",
  },
  actions: {
    async fetchProfile() {
      this.loading = true;
      this.error = null;
      const keycloakTenants = KeycloakService.GetTenants();
      const httpService = HttpService.getAxiosClient();

      try {
        const {
          data: { profile_info },
        } = await httpService.get(`${keycloakTenants[0]}/me?module=REL900`);
        this.profile = profile_info[0];

        this.userNavigation = profile_info.map((tenant: any) => ({
          name: tenant.empresa,
          tenant: tenant.cnpj,
        }));

        if (!this.selectedTenant) {
          this.selectedTenant = this.userNavigation[0];
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        this.error = "Failed to fetch profile";
      } finally {
        this.loading = false;
      }
    },
    changeTenant(tenant: Tenant) {
      this.selectedTenant = tenant;
    },
  },
});
