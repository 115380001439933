<template>
  <AppNavbar @toggle-sidebar="toggleSidebar" />
  <AppSidebar ref="sidebar" />
  <div class="lg:pl-72 pt-14 mx-auto">
    <main class="py-10">
      <div class="px-4 sm:px-6 lg:px-8">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import AppNavbar from "@/components/app-navbar.vue";
import AppSidebar from "@/components/app-sidebar.vue";
import { useProfileStore } from "@/stores/useProfileStore";

// Reference to sidebar component
const sidebar = ref<InstanceType<typeof AppSidebar> | null>(null);

// Profile store instance
const profileStore = useProfileStore();

// Toggle sidebar visibility
const toggleSidebar = () => {
  if (sidebar.value) {
    sidebar.value.sidebarOpen = !sidebar.value.sidebarOpen;
  }
};

// Fetch profile and handle errors without locking screen
onMounted(async () => {
  try {
    await profileStore.fetchProfile();
  } catch (error) {
    console.error("Error fetching profile:", error);
    alert(
      "Erro ao carregar dados do perfil. Por favor, contate o administrador."
    );
  }
});
</script>
