import DashboardIcon from "@/components/icons/dashboard-icon.vue";
import StockIcon from "@/components/icons/stock-icon.vue";
import SalesIcon from "@/components/icons/sale-icon.vue";
export const navigation = [
  {
    name: "Visão geral",
    href: "/",
    icon: DashboardIcon,
  },
  {
    name: "Vendas",
    href: "/vendas",
    icon: SalesIcon,
  },
  // { name: "Estoque", href: "/estoque", icon: StockIcon },
];
