<template>
  <Menu as="div" class="relative">
    <MenuButton
      class="flex items-center justify-between p-2 border border-parrot-1 rounded-md w-full lg:w-52 text-white hover:bg-parrot-1 transition-colors duration-200"
    >
      <span class="sr-only">Abrir empresas</span>
      <div v-if="loading" class="text-white">Loading...</div>
      <div v-else-if="error" class="text-red-500">{{ error }}</div>
      <div v-else class="flex flex-col items-start">
        <span class="text-sm font-semibold leading-6 line-clamp-1">
          {{ selectedTenant?.name }}
        </span>
        <span class="text-xs leading-5">
          {{ formatCNPJ(selectedTenant?.tenant) }}
        </span>
      </div>
      <ChevronDownIcon
        v-if="userNavigation.length > 1"
        class="h-5 w-5 text-white"
        aria-hidden="true"
      />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        v-if="userNavigation.length > 1"
        class="absolute w-full right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem
            v-for="item in userNavigation"
            :key="item.tenant"
            v-slot="{ active }"
          >
            <a
              href="#"
              :class="[
                active ? 'bg-gray-100 text-parrot' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              @click="changeTenant(item)"
            >
              <div class="flex items-center">
                <CheckIcon
                  v-if="item.tenant === selectedTenant?.tenant"
                  class="mr-2 h-4 w-4 text-parrot"
                  aria-hidden="true"
                />
                <span
                  :class="{ 'ml-6': item.tenant !== selectedTenant?.tenant }"
                >
                  {{ formatCNPJ(item.tenant) }}
                </span>
              </div>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { useProfileStore } from "@/stores/useProfileStore";
import { storeToRefs } from "pinia";

const profileStore = useProfileStore();
const { loading, error, selectedTenant, userNavigation } =
  storeToRefs(profileStore);
const { changeTenant } = profileStore;

function formatCNPJ(cnpj: string | undefined): string {
  if (!cnpj) return "";
  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}
</script>
