import Keycloak from "keycloak-js";

const SSO_URL = process.env.VUE_APP_SSO_URL;
const keycloakInstance = new Keycloak({
  url: SSO_URL,
  realm: "master",
  clientId: "rede",
});

interface CallbackOneParam<T1 = void, T2 = void> {
  (param1: T1): T2;
}
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback: CallbackOneParam): void => {
  keycloakInstance
    .init({ onLoad: "login-required", checkLoginIframe: false })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert("non authenticated");
    })
    .catch((e) => {
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};

const UserName = (): string | undefined =>
  keycloakInstance?.tokenParsed?.preferred_username;

const tenants = (): string[] | [] => keycloakInstance?.tokenParsed?.tenants;

const fullName = (): string | undefined => keycloakInstance?.tokenParsed?.name;

const Token = (): string | undefined => keycloakInstance?.token;

const logout = () =>
  keycloakInstance.logout({ redirectUri: process.env.VUE_APP_LOGOUT_URI });

const UserRoles = (): string[] | undefined => {
  if (keycloakInstance.resourceAccess === undefined) return undefined;
  if (keycloakInstance.resourceAccess["vuejs"] === undefined) return undefined;

  return keycloakInstance.resourceAccess["vuejs"].roles;
};

const updateToken = (successCallback: any) =>
  keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);

const doLogin = keycloakInstance.login;

const isLoggedIn = () => !!keycloakInstance.token;

const KeycloakService = {
  CallLogin: Login,
  GetUserName: UserName,
  GetAccesToken: Token,
  callLogOut: logout,
  GetTenants: tenants,
  getFullName: fullName,
  GetUserRoles: UserRoles,
  UpdateToken: updateToken,
  IsLoggedIn: isLoggedIn,
};

export default KeycloakService;
